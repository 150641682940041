import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    centered: "",
    size: "xl",
    "cancel-button": _ctx.titleCase(_ctx.t('core.button.cancel')),
    "model-value": _ctx.state.show,
    title: _ctx.title,
    onHidden: _ctx.onClose
  }, {
    "btn-ok": _withCtx(() => [
      _createVNode(_component_b_button, {
        variant: "primary",
        disabled: _ctx.state.saving,
        onClick: _ctx.savePlannedPart
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.titleCase(_ctx.$t('core.button.save'))) + " ", 1),
          (_ctx.state.saving)
            ? (_openBlock(), _createBlock(_component_b_spinner, {
                key: 0,
                small: ""
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_autocomplete_single_select, {
                modelValue: _ctx.state.plannedPart.part.number,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.plannedPart.part.number) = $event)),
                loading: _ctx.state.loading,
                cols: "4",
                data: _ctx.productionParts,
                "search-by": "number",
                "on-before-clear": _ctx.clearPartNumber,
                "empty-text": "No parts",
                "display-name": "number",
                label: _ctx.titleCase(_ctx.t('core.domain.partNumber')),
                class: "form-col",
                error: _ctx.plannedPartValidationResult.model.part
              }, null, 8, ["modelValue", "loading", "data", "on-before-clear", "label", "error"]),
              _createVNode(_component_dropdown_autocomplete_single_select, {
                "model-value": _ctx.state.plannedPart.fromLocation?.nameAndNumber,
                loading: _ctx.state.loading,
                cols: "4",
                data: _ctx.state.fromLocations,
                "search-by": "nameAndNumber",
                "on-before-clear": async () => _ctx.clearAffectedFields(true),
                "empty-text": "No locations",
                "display-name": "nameAndNumber",
                label: _ctx.titleCase(_ctx.t('core.domain.shipFrom')),
                class: "form-col",
                disabled: _ctx.isFromPartnerEngagement,
                error: _ctx.plannedPartValidationResult.model.fromLocation,
                onOnSelect: _ctx.selectFromLocation
              }, null, 8, ["model-value", "loading", "data", "on-before-clear", "label", "disabled", "error", "onOnSelect"]),
              _createVNode(_component_dropdown_autocomplete_single_select, {
                "model-value": _ctx.state.plannedPart.toLocation?.nameAndNumber,
                loading: _ctx.state.loading,
                cols: "4",
                data: _ctx.state.toLocations,
                "search-by": "nameAndNumber",
                "on-before-clear": _ctx.clearAffectedFields,
                "empty-text": "No locations",
                "display-name": "nameAndNumber",
                label: _ctx.titleCase(_ctx.t('core.domain.shipTo')),
                class: "form-col",
                error: _ctx.plannedPartValidationResult.model.toLocation,
                disabled: !_ctx.hasFromLocation,
                onOnSelect: _ctx.selectToLocation
              }, null, 8, ["model-value", "loading", "data", "on-before-clear", "label", "error", "disabled", "onOnSelect"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_date_input, {
                modelValue: _ctx.state.plannedPart.effectiveFrom,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.plannedPart.effectiveFrom) = $event)),
                label: _ctx.titleCase(_ctx.t('core.domain.effectiveFromDate')),
                cols: "4",
                class: "form-col",
                clearable: true,
                "lower-limit": new Date(1900, 0, 1),
                error: _ctx.plannedPartValidationResult.model.effectiveFrom
              }, null, 8, ["modelValue", "label", "lower-limit", "error"]),
              _createVNode(_component_date_input, {
                modelValue: _ctx.state.plannedPart.effectiveTo,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.plannedPart.effectiveTo) = $event)),
                label: _ctx.titleCase(_ctx.t('core.domain.effectiveToDate')),
                cols: "4",
                class: "form-col",
                clearable: true,
                error: _ctx.plannedPartValidationResult.model.effectiveTo
              }, null, 8, ["modelValue", "label", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_autocomplete_single_select, {
                "model-value": _ctx.state.plannedPart.item?.nameAndNumber,
                loading: _ctx.state.loading,
                cols: "4",
                data: _ctx.state.items,
                "search-by": "nameAndNumber",
                "empty-text": "No items",
                "display-name": "nameAndNumber",
                label: _ctx.titleCase(_ctx.t('core.domain.item')),
                class: "form-col",
                error: _ctx.plannedPartValidationResult.model.item,
                disabled: !_ctx.areLocationsSelected,
                onOnSelect: _ctx.selectItem
              }, null, 8, ["model-value", "loading", "data", "label", "error", "disabled", "onOnSelect"]),
              _createVNode(_component_text_input, {
                modelValue: _ctx.state.plannedPart.quantityPerItem,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.plannedPart.quantityPerItem) = $event)),
                error: _ctx.plannedPartValidationResult.model.quantityPerItem,
                "hide-stepper": "",
                label: _ctx.titleCase(_ctx.t('core.domain.quantityPerContainer')),
                type: "number",
                cols: "4",
                class: "form-col"
              }, null, 8, ["modelValue", "error", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, { class: "rowCenter" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_form_checkbox, {
                modelValue: _ctx.state.plannedPart.isPalletized,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.plannedPart.isPalletized) = $event)),
                error: _ctx.plannedPartValidationResult.model.isPalletized,
                label: _ctx.titleCase(_ctx.t('core.domain.palletized')),
                class: "checkbox form-col",
                disabled: !_ctx.areLocationsSelected,
                onChange: _ctx.checkIsPalletized
              }, null, 8, ["modelValue", "error", "label", "disabled", "onChange"]),
              _createVNode(_component_dropdown_autocomplete_single_select, {
                "model-value": _ctx.state.plannedPart.palletItem?.nameAndNumber,
                loading: _ctx.state.loading,
                cols: "4",
                data: _ctx.state.items,
                "search-by": "nameAndNumber",
                "empty-text": "No items",
                "display-name": "nameAndNumber",
                label: _ctx.titleCase(_ctx.t('core.domain.palletContainerNumber')),
                class: "form-col",
                error: _ctx.plannedPartValidationResult.model.palletItem,
                disabled: !_ctx.areLocationsSelected || !_ctx.isPalletized,
                "null-if-empty": "",
                onOnSelect: _ctx.selectPalletItem
              }, null, 8, ["model-value", "loading", "data", "label", "error", "disabled", "onOnSelect"]),
              _createVNode(_component_text_input, {
                modelValue: _ctx.state.plannedPart.itemsPerPallet,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.plannedPart.itemsPerPallet) = $event)),
                error: _ctx.plannedPartValidationResult.model.itemsPerPallet,
                "hide-stepper": "",
                label: _ctx.titleCase(_ctx.t('core.domain.containersPerPallet')),
                type: "number",
                cols: "4",
                class: "checkboxInput form-col",
                disabled: !_ctx.areLocationsSelected || !_ctx.isPalletized
              }, null, 8, ["modelValue", "error", "label", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, { class: "rowCenter" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_form_checkbox, {
                modelValue: _ctx.state.plannedPart.includeTopCap,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.plannedPart.includeTopCap) = $event)),
                error: _ctx.plannedPartValidationResult.model.includeTopCap,
                disabled: !_ctx.isPalletized,
                label: _ctx.titleCase(_ctx.t('core.domain.includeTopCap')),
                class: "checkbox form-col",
                onChange: _ctx.checkIncludeTopCap
              }, null, 8, ["modelValue", "error", "disabled", "label", "onChange"]),
              _createVNode(_component_dropdown_autocomplete_single_select, {
                "model-value": _ctx.state.plannedPart.topCapItem?.nameAndNumber,
                loading: _ctx.state.loading,
                cols: "4",
                data: _ctx.state.items,
                "search-by": "nameAndNumber",
                "empty-text": "No items",
                "display-name": "nameAndNumber",
                label: _ctx.titleCase(_ctx.t('core.domain.topCapItemNumber')),
                class: "form-col",
                error: _ctx.plannedPartValidationResult.model.topCapItem,
                disabled: !_ctx.areLocationsSelected || !_ctx.includeTopCap,
                onOnSelect: _ctx.selectTopCapItem
              }, null, 8, ["model-value", "loading", "data", "label", "error", "disabled", "onOnSelect"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, { class: "rowCenter" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_form_checkbox, {
                modelValue: _ctx.state.plannedPart.enabled,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.plannedPart.enabled) = $event)),
                error: _ctx.plannedPartValidationResult.model.enabled,
                label: _ctx.titleCase(_ctx.t('core.domain.enabled')),
                class: "checkbox enabledCheckbox form-col"
              }, null, 8, ["modelValue", "error", "label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["cancel-button", "model-value", "title", "onHidden"]))
}