
import {
    computed, defineComponent, reactive, onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import ProductionPart from '@/domain/ProductionPart';
import useValidator from '@/validation/useValidator';
import ProductionPartService from '@/services/ProductionPartService';
import useStringFormatter from '@/composable/useStringFormatter';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';

type State = {
    productionPart: ProductionPart;
    saving: boolean;
    show: boolean;
};

export default defineComponent({
    name: 'add-production-part-modal',
    components: {
        BModal,
    },
    props: {
        productionPartEdit: { type: ProductionPart, default: undefined },
    },
    emits: ['onSave', 'onClose'],
    setup(props, context) {
        const { t } = useI18n();
        const { titleCase } = useStringFormatter();

        const state = reactive<State>({
            productionPart: new ProductionPart(),
            saving: false,
            show: true,
        });

        const partService = new ProductionPartService();

        const productionPartValidation = useValidator<ProductionPart>('production-part-add');
        const productionPartValidationResult = productionPartValidation.validationResult;

        const title = computed(() => (state.productionPart.id ? titleCase(t('core.button.editProductionPart')) : titleCase(t('core.button.addProductionPart'))));

        onMounted(async () => {
            if (props.productionPartEdit?.id) {
                state.productionPart = props.productionPartEdit;
            }
        });

        async function saveProductionPart() {
            productionPartValidation.validateForm(state.productionPart);

            if (productionPartValidation.validationResult.isValid) {
                state.saving = true;

                if (state.productionPart.id) {
                    const response = await partService.updateProductionPart(state.productionPart);
                    if (response) {
                        context.emit('onSave', response);
                    }
                } else {
                    const response = await partService.addProductionPart(state.productionPart);
                    if (response) {
                        context.emit('onSave', response);
                    }
                }
            }

            state.saving = false;
        }

        function onClose() {
            context.emit('onClose');
        }

        return {
            state,
            productionPartValidationResult,
            title,

            onClose,
            saveProductionPart,
            t,
            titleCase,
        };
    },
});
