
import {
    computed, defineComponent, onMounted, PropType, reactive,
} from 'vue';
import { useI18n } from 'vue-i18n';
import useValidator from '@/validation/useValidator';
import PlannedPart from '@/domain/PlannedPart';
import ProductionPartService from '@/services/ProductionPartService';
import Location from '@/domain/Location';
import CoreStore from '@/store/CoreStore';
import RouteConfigService from '@/services/RouteConfigService';
import Item from '@/domain/Item';
import LocationRouteConfig from '@/domain/LocationRouteConfig';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import ProductionPart from '@/domain/ProductionPart';
import PlannedPartDTO from '@/dtos/PlannedPartDTO';
import useStringFormatter from '@/composable/useStringFormatter';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';

type State = {
    plannedPart: PlannedPart;
    saving: boolean;
    loading: boolean;
    show: boolean;

    items: Array<Item>;
    routeConfigs: Array<LocationRouteConfig>;

    toLocations: Array<Location>;
    fromLocations: Array<Location>;
};

export default defineComponent({
    name: 'add-planned-part-modal',
    components: {
        BModal,
        DropdownAutocompleteSingleSelect,
    },
    props: {
        productionParts: { type: Array as PropType<Array<ProductionPart>>, required: true },
        plannedPartEditDto: { type: PlannedPartDTO, default: new PlannedPartDTO() },
        productionPartId: { type: Number, default: 0 },
        isFromPartnerEngagement: { type: Boolean, default: false },
    },
    emits: ['onSave', 'onClose'],
    setup(props, context) {
        const { userLocation } = CoreStore.getInstance().profileStore;

        const partService = new ProductionPartService();
        const routeConfigService = new RouteConfigService();

        const { t } = useI18n();
        const { titleCase } = useStringFormatter();

        const state = reactive<State>({
            plannedPart: new PlannedPart(),
            saving: false,
            loading: false,
            show: true,

            items: [],
            routeConfigs: [],

            toLocations: [],
            fromLocations: [],
        });

        async function getFromLocations(): Promise<Array<Location>> {
            const fromLocationResponse = await routeConfigService.getAllFromLocations();
            return fromLocationResponse.locations;
        }

        async function getRouteConfigs(fromLocationId: number) {
            state.routeConfigs = await routeConfigService.getRouteConfigsFromLocation(fromLocationId);
            state.toLocations = state.routeConfigs.map((location) => location.toLocation);
        }

        onMounted(async () => {
            state.loading = true;

            if (props.isFromPartnerEngagement) {
                state.fromLocations.push(userLocation);
                state.plannedPart.setFromLocation(userLocation);
                await getRouteConfigs(userLocation.id);
            } else {
                state.fromLocations = await getFromLocations();
            }

            if (props.plannedPartEditDto.id) {
                state.plannedPart = new PlannedPart(props.plannedPartEditDto);
                state.plannedPart.setFromLocation(new Location(state.fromLocations.find((loc) => loc.id === props.plannedPartEditDto.fromLocationId)));

                await getRouteConfigs(props.plannedPartEditDto.fromLocationId);

                state.plannedPart.setToLocation(new Location(state.toLocations.find((loc) => loc.id === props.plannedPartEditDto.toLocationId)));

                const response = await routeConfigService.getAllItemsInRouteConfig(state.plannedPart.fromLocation.id, state.plannedPart.toLocation.id);
                state.items = response.filter((item) => !item.isUnitLoad);
                state.plannedPart.setItem(new Item(state.items.find((item) => item.id === props.plannedPartEditDto.itemId)));

                if (props.plannedPartEditDto.isPalletized) {
                    state.plannedPart.setPalletItem(new Item(state.items.find((item) => item.id === props.plannedPartEditDto.palletItemId)));
                }

                if (props.plannedPartEditDto.includeTopCap) {
                    state.plannedPart.setTopCapItem(new Item(state.items.find((item) => item.id === props.plannedPartEditDto.topCapItemId)));
                }
            }

            if (props.productionPartId) {
                state.plannedPart.setPart(new ProductionPart(props.productionParts.find((part) => part.id === props.productionPartId)));
            }

            state.loading = false;
        });

        const areLocationsSelected = computed(() => state.plannedPart.fromLocation && state.plannedPart.fromLocation.id && state.plannedPart.toLocation && state.plannedPart.toLocation.id);
        const hasFromLocation = computed(() => state.plannedPart.fromLocation && state.plannedPart.fromLocation.id);

        const isPalletized = computed(() => state.plannedPart.isPalletized);
        const includeTopCap = computed(() => state.plannedPart.includeTopCap);

        const plannedPartValidation = useValidator<PlannedPart>('planned-part-add');
        const plannedPartValidationResult = plannedPartValidation.validationResult;

        const title = computed(() => (state.plannedPart.id ? t('core.button.editPlannedPart') : t('core.button.addPlannedPart')));

        async function clearAffectedFields(isFromLocation: boolean = false): Promise<boolean> {
            if (isFromLocation) {
                state.plannedPart.setFromLocation(new Location());
            }

            state.plannedPart.clear();

            state.loading = false;
            return true;
        }

        async function selectFromLocation(location: Location) {
            state.loading = true;
            state.plannedPart.setFromLocation(location);

            if (location) {
                await getRouteConfigs(location.id);
            }

            state.loading = false;
        }

        async function selectToLocation(location: Location) {
            state.loading = true;
            state.plannedPart.setToLocation(location);

            if (location) {
                const response = await routeConfigService.getAllItemsInRouteConfig(state.plannedPart.fromLocation.id, location.id);
                state.items = response.filter((item) => !item.isUnitLoad);
            }

            state.loading = false;
        }

        async function clearPartNumber(): Promise<boolean> {
            state.loading = true;

            state.plannedPart.setPart(new ProductionPart());

            state.loading = false;
            return true;
        }

        async function savePlannedPart() {
            const selectedPart = props.productionParts.find((part) => part?.number === state.plannedPart.part.number) || new ProductionPart();

            if (selectedPart) {
                state.plannedPart.setPart(selectedPart);
            }

            plannedPartValidation.validateForm(state.plannedPart);
            if (plannedPartValidation.validationResult.isValid) {
                state.saving = true;

                if (state.plannedPart.id) {
                    const response = await partService.updatePlannedPart(state.plannedPart.toDTO());
                    if (response) {
                        context.emit('onSave', response);
                    }
                } else {
                    const response = await partService.addPlannedPart(state.plannedPart.toDTO());
                    if (response) {
                        context.emit('onSave', response);
                    }
                }
            }

            state.saving = false;
        }

        async function selectItem(item: Item) {
            state.plannedPart.setItem(item);
        }

        async function selectPalletItem(item: Item) {
            state.plannedPart.setPalletItem(item);
        }

        async function selectTopCapItem(item: Item) {
            state.plannedPart.setTopCapItem(item);
        }

        function checkIsPalletized(value: boolean) {
            if (!value || !areLocationsSelected.value) {
                state.plannedPart.setIsPalletized(false);
                state.plannedPart.clearPalletAndTopCap();
            }
        }

        function checkIncludeTopCap(value: boolean) {
            if (!value || !isPalletized.value) {
                state.plannedPart.setIncludeTopCap(false);
                state.plannedPart.setTopCapItem(undefined);
            }
        }

        function onClose() {
            context.emit('onClose');
        }

        return {
            state,
            areLocationsSelected,
            hasFromLocation,
            isPalletized,
            includeTopCap,
            plannedPartValidationResult,
            title,

            checkIncludeTopCap,
            checkIsPalletized,
            clearAffectedFields,
            clearPartNumber,
            onClose,
            savePlannedPart,
            selectFromLocation,
            selectItem,
            selectPalletItem,
            selectToLocation,
            selectTopCapItem,
            t,
            titleCase,
        };
    },
});
