import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_production_part_modal = _resolveComponent("add-production-part-modal")!
  const _component_add_planned_part_modal = _resolveComponent("add-planned-part-modal")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_simple_date_popover = _resolveComponent("simple-date-popover")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      (_ctx.state.showProductionPartModal)
        ? (_openBlock(), _createBlock(_component_add_production_part_modal, {
            key: 0,
            "production-part-edit": _ctx.state.productionPartEdit,
            onOnSave: _ctx.saveProductionPart,
            onOnClose: _ctx.closeProductionPartModal
          }, null, 8, ["production-part-edit", "onOnSave", "onOnClose"]))
        : _createCommentVNode("", true),
      (_ctx.state.showPlannedPartModal)
        ? (_openBlock(), _createBlock(_component_add_planned_part_modal, {
            key: 1,
            "production-part-id": _ctx.state.productionPartId,
            "production-parts": _ctx.state.productionParts,
            "planned-part-edit-dto": _ctx.state.plannedPartEditDTO,
            onOnSave: _ctx.savePlannedPart,
            onOnClose: _ctx.closePlannedPartModal
          }, null, 8, ["production-part-id", "production-parts", "planned-part-edit-dto", "onOnSave", "onOnClose"]))
        : _createCommentVNode("", true),
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        loading: _ctx.state.loading || _ctx.filterSearchState.loading,
        "dynamic-columns": "",
        "side-pane-options": {
                hideHeader: true,
            }
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            disabled: !_ctx.Permissions.ADMINISTRATION.canEditProductionPartData(),
            "disabled-message": _ctx.getTranslation('core.common.missingProductionPartAddPermission'),
            onClick: _ctx.openAddProductionPartModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addProductionPart')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "disabled-message", "onClick"]),
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            disabled: !_ctx.Permissions.ADMINISTRATION.canEditProductionPartData(),
            "disabled-message": _ctx.getTranslation('core.common.youDoNotHavePermissionToAddPlannedParts'),
            onClick: _ctx.openAddPlannedPartModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTranslation('core.button.addPlannedPart')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "disabled-message", "onClick"])
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                disabled: !_ctx.Permissions.ADMINISTRATION.canEditProductionPartData(),
                "disabled-message": _ctx.getTranslation('core.common.missingProductionPartEditPermission'),
                onClick: () => _ctx.editProductionPart(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.editProductionPart')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "disabled-message", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                disabled: !_ctx.Permissions.ADMINISTRATION.canEditProductionPartData(),
                "disabled-message": _ctx.getTranslation('core.common.youDoNotHavePermissionToEditPlannedParts'),
                onClick: () => _ctx.editPlannedPart(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTranslation('core.button.editPlannedPart')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "disabled-message", "onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        "cell(effectiveFrom)": _withCtx((row) => [
          _createVNode(_component_simple_date_popover, {
            date: row.item.effectiveFrom
          }, null, 8, ["date"])
        ]),
        "cell(effectiveTo)": _withCtx((row) => [
          _createVNode(_component_simple_date_popover, {
            date: row.item.effectiveTo
          }, null, 8, ["date"])
        ]),
        _: 1
      }, 8, ["table-array", "loading"])
    ]),
    _: 1
  }))
}