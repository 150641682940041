
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import AdvancedFilters from '@/components/filters/AdvancedFilters.vue';
import FilterCriteria from '@/components/filters/FilterCriteria.vue';
import FormMultiSelect from '@/components/FormMultiSelect.vue';
import PlannedPartSearchFilter from '@/dtos/filters/PlannedPartSearchFilter';
import ProductionPart from '@/domain/ProductionPart';
import Item from '@/domain/Item';
import Location from '@/domain/Location';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'planned-part-advanced-filters',
    components: {
        AdvancedFilters,
        FilterCriteria,
        FormMultiSelect,
    },
    props: {
        filterDefault: Boolean,
        filterDirty: Boolean,
        modelValue: {
            type: Object as PropType<PlannedPartSearchFilter>,
            default: () => new PlannedPartSearchFilter(),
        },
        productionParts: {
            type: Array as PropType<Array<ProductionPart>>,
            required: true,
        },
        itemList: {
            type: Array as PropType<Array<Item>>,
            required: true,
        },
        fromLocationList: {
            type: Array as PropType<Array<Location>>,
            default: () => [],
        },
        toLocationList: {
            type: Array as PropType<Array<Location>>,
            default: () => [],
        },
        showDisabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reset', 'submit'],
    setup(props, context) {
        const { t } = useI18n();
        const { titleCase } = useStringFormatter();

        function reset() {
            context.emit('reset');
        }

        function submit() {
            context.emit('submit');
        }

        return {
            getTitleCaseTranslation,
            reset,
            submit,

            t,
            titleCase,
        };
    },
});
