import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    centered: "",
    "cancel-button": _ctx.titleCase(_ctx.t('core.button.cancel')),
    "model-value": _ctx.state.show,
    title: _ctx.title,
    onHidden: _ctx.onClose
  }, {
    "btn-ok": _withCtx(() => [
      _createVNode(_component_b_button, {
        variant: "primary",
        disabled: _ctx.state.saving,
        onClick: _ctx.saveProductionPart
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.titleCase(_ctx.$t('core.button.save'))) + " ", 1),
          (_ctx.state.saving)
            ? (_openBlock(), _createBlock(_component_b_spinner, {
                key: 0,
                small: ""
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_text_input, {
                modelValue: _ctx.state.productionPart.number,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.productionPart.number) = $event)),
                label: _ctx.titleCase(_ctx.t('core.domain.partNumber')),
                md: "5",
                error: _ctx.productionPartValidationResult.model.number
              }, null, 8, ["modelValue", "label", "error"]),
              _createVNode(_component_text_input, {
                modelValue: _ctx.state.productionPart.description,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.productionPart.description) = $event)),
                label: _ctx.titleCase(_ctx.t('core.domain.description')),
                md: "5",
                error: _ctx.productionPartValidationResult.model.description
              }, null, 8, ["modelValue", "label", "error"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["cancel-button", "model-value", "title", "onHidden"]))
}