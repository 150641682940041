import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_filter_criteria = _resolveComponent("filter-criteria")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_advanced_filters = _resolveComponent("advanced-filters")!

  return (_openBlock(), _createBlock(_component_advanced_filters, {
    "filter-default": _ctx.filterDefault,
    "filter-dirty": _ctx.filterDirty,
    onReset: _ctx.reset,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.productionParts')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_form_multi_select, {
            modelValue: _ctx.modelValue.productionParts,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.productionParts) = $event)),
            "search-by": "number",
            data: _ctx.productionParts
          }, null, 8, ["modelValue", "data"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.items')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_form_multi_select, {
            modelValue: _ctx.modelValue.items,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.items) = $event)),
            "search-by": "name",
            data: _ctx.itemList
          }, null, 8, ["modelValue", "data"])
        ]),
        _: 1
      }),
      (_ctx.fromLocationList.length > 0)
        ? (_openBlock(), _createBlock(_component_filter_criteria, { key: 0 }, {
            header: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.fromLocations')), 1)
            ]),
            content: _withCtx(() => [
              _createVNode(_component_form_multi_select, {
                modelValue: _ctx.modelValue.fromLocations,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.fromLocations) = $event)),
                "search-by": "name",
                data: _ctx.fromLocationList
              }, null, 8, ["modelValue", "data"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.toLocationList.length > 0)
        ? (_openBlock(), _createBlock(_component_filter_criteria, { key: 1 }, {
            header: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.toLocations')), 1)
            ]),
            content: _withCtx(() => [
              _createVNode(_component_form_multi_select, {
                modelValue: _ctx.modelValue.toLocations,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.toLocations) = $event)),
                "search-by": "name",
                data: _ctx.toLocationList
              }, null, 8, ["modelValue", "data"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.titleCase(_ctx.t('core.common.showDisabled'))), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.showDisabled,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.showDisabled) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["filter-default", "filter-dirty", "onReset", "onSubmit"]))
}