
import {
    computed, defineComponent, h, onBeforeMount, reactive,
} from 'vue';
import ProductionPart from '@/domain/ProductionPart';
import Item from '@/domain/Item';
import Location from '@/domain/Location';
import ProductionPartService from '@/services/ProductionPartService';
import RouteConfigService from '@/services/RouteConfigService';
import { TableDefinition } from '@/types';
import Permissions from '@/services/permissions/Permissions';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import AddProductionPartModal from '@/components/modals/AddProductionPartModal.vue';
import AddPlannedPartModal from '@/components/modals/AddPlannedPartModal.vue';
import PlannedPartDTO from '@/dtos/PlannedPartDTO';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import PlannedPartAdvancedFilters from '@/components/filters/PlannedPartAdvancedFilters.vue';
import PlannedPartSearchFilter from '@/dtos/filters/PlannedPartSearchFilter';
import { useFilterSearch } from '@/composable/useFilterSearch';
import WarningIcon from '@/components/WarningIcon.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

type State = {
    allFromLocations: Array<Location>;
    allToLocations: Array<Location>;
    itemsWithRoute: Array<Item>;
    plannedPartEditDTO: PlannedPartDTO;
    productionParts: Array<ProductionPart>;
    productionPartId: number;
    productionPartEdit: ProductionPart;

    loading: boolean;

    showProductionPartModal: boolean;
    showPlannedPartModal: boolean;
};

export default defineComponent({
    name: 'production-part-list',
    components: {
        AddProductionPartModal,
        BAdvancedTable,
        AddPlannedPartModal,
        SimpleDatePopover,
    },

    setup() {
        const partService = new ProductionPartService();
        const routeConfigService = new RouteConfigService();

        const { confirm } = useDialogBox();

        const state = reactive<State>({
            itemsWithRoute: [],
            allFromLocations: [],
            allToLocations: [],
            productionParts: [],
            productionPartId: 0,
            productionPartEdit: new ProductionPart(),
            plannedPartEditDTO: new PlannedPartDTO(),
            loading: false,

            showPlannedPartModal: false,
            showProductionPartModal: false,
        });

        const {
            filterState, isFilterDefault, isFilterDirty, reset, state: filterSearchState, submit: filterSearch, recordCountWarning,
        } = useFilterSearch<PlannedPartDTO, PlannedPartSearchFilter>({
            tableKey: 'masterDataPlannedPartList',
            defaultFilters: new PlannedPartSearchFilter(),
            searchFunction: async (filter: PlannedPartSearchFilter) => partService.getFilteredPlannedParts(filter),
        });

        const table = computed(
            (): TableDefinition<PlannedPartDTO> => ({
                items: filterSearchState.resultSet,
                key: 'masterDataPlannedPartList',
                name: getTranslation('core.common.planForEveryPartTitle'),
                columnDefinition: [
                    {
                        label: getTitleCaseTranslation('core.domain.partNumber'),
                        key: 'partNumber',
                        searchable: true,
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.shipFrom'),
                        key: 'fromLocationNameAndNumber',
                        searchable: true,
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.shipTo'),
                        key: 'toLocationNameAndNumber',
                        searchable: true,
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.effectiveFromDateShort'),
                        key: 'effectiveFrom',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.effectiveToDateShort'),
                        key: 'effectiveTo',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.item'),
                        key: 'itemNameAndNumber',
                        searchable: true,
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.quantityPerContainer'),
                        key: 'quantityPerItem',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.palletized'),
                        key: 'isPalletizedView',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.palletContainerNumber'),
                        key: 'palletItemNameAndNumber',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.containersPerPallet'),
                        key: 'itemsPerPallet',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.includeTopCap'),
                        key: 'includeTopCapView',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.topCapItemNumber'),
                        key: 'topCapItemNameAndNumber',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.enabled'),
                        key: 'enabledView',
                    },
                ],
                headIcon: () => h(WarningIcon, {
                    message: recordCountWarning.value,
                }),
                sidePane: () => h(PlannedPartAdvancedFilters, {
                    onSubmit: filterSearch,
                    onReset: reset,
                    modelValue: filterState.value,
                    disabled: state.loading,
                    filterDirty: isFilterDirty.value,
                    filterDefault: isFilterDefault.value,
                    productionParts: state.productionParts,
                    itemList: state.itemsWithRoute,
                    fromLocationList: state.allFromLocations,
                    toLocationList: state.allToLocations,
                }),
            }),
        );

        async function getProductionParts() {
            const productionPartsResponse = await partService.getAllProductionParts();
            state.productionParts = productionPartsResponse.parts;
        }

        function openAddPlannedPartModal() {
            state.productionPartId = 0;
            state.plannedPartEditDTO = new PlannedPartDTO();
            state.showPlannedPartModal = true;
        }

        function openAddProductionPartModal() {
            state.productionPartEdit = new ProductionPart();
            state.showProductionPartModal = true;
        }

        function editPlannedPart(plannedPartDTO: PlannedPartDTO) {
            state.plannedPartEditDTO = plannedPartDTO;
            state.productionPartId = plannedPartDTO.partId;
            state.showPlannedPartModal = true;
        }

        function editProductionPart(plannedPartDTO: PlannedPartDTO) {
            state.plannedPartEditDTO = plannedPartDTO;
            state.productionPartEdit = new ProductionPart(state.productionParts.find((part) => part.id === plannedPartDTO?.partId));
            state.showProductionPartModal = true;
        }

        function savePlannedPart() {
            state.loading = true;

            filterSearch();
            state.showPlannedPartModal = false;

            state.loading = false;
        }

        async function saveProductionPart(productionPart: ProductionPart) {
            state.loading = true;

            await getProductionParts();
            filterSearch();

            state.showProductionPartModal = false;

            if (state.productionPartEdit.isNew) {
                const addPfep = await confirm({
                    title: getTranslation('core.common.createPFEP'),
                    message: getTranslation('core.common.addPFEPForThisProductionPart'),
                });

                if (addPfep) {
                    state.productionPartId = productionPart.id;
                    state.showPlannedPartModal = true;
                }
            }

            state.productionPartEdit = new ProductionPart();
            state.plannedPartEditDTO = new PlannedPartDTO();

            state.loading = false;
        }

        async function closePlannedPartModal() {
            state.showPlannedPartModal = false;
        }

        async function closeProductionPartModal() {
            state.showProductionPartModal = false;
        }

        onBeforeMount(async () => {
            state.loading = true;

            await getProductionParts();

            const itemsResponse = await routeConfigService.getAllItemsWithRoute();
            state.itemsWithRoute = itemsResponse.items;

            const fromLocationResponse = await routeConfigService.getAllFromLocations();
            state.allFromLocations = fromLocationResponse.locations;

            const toLocationResponse = await routeConfigService.getAllToLocations();
            state.allToLocations = toLocationResponse.locations;

            filterSearch();

            state.loading = false;
        });

        return {
            filterSearchState,
            Permissions,
            state,
            table,

            closePlannedPartModal,
            closeProductionPartModal,
            editPlannedPart,
            editProductionPart,
            getTitleCaseTranslation,
            getTranslation,
            openAddPlannedPartModal,
            openAddProductionPartModal,
            savePlannedPart,
            saveProductionPart,
        };
    },
});
