/* eslint-disable max-classes-per-file */

import Item from '@/domain/Item';
import Location from '@/domain/Location';
import ProductionPart from '@/domain/ProductionPart';
import SearchFilterBase from '@/dtos/filters/SearchFilterBase';

export class PlannedPartSearchFilterDTO extends SearchFilterBase {
    itemIds: number[];

    fromLocationIds: number[];

    toLocationIds: number[];

    productionPartIds: number[];

    showDisabled: boolean;

    constructor(init: PlannedPartSearchFilter) {
        super(init.recordCount);
        this.itemIds = init.items.map((item) => item.id);
        this.fromLocationIds = init.fromLocations.map((location) => location.id);
        this.toLocationIds = init.toLocations.map((location) => location.id);
        this.productionPartIds = init.productionParts.map((productionPart) => productionPart.id);
        this.showDisabled = init.showDisabled;
    }
}

export default class PlannedPartSearchFilter extends SearchFilterBase {
    items: Item[] = [];

    fromLocations: Location[] = [];

    toLocations: Location[] = [];

    productionParts: ProductionPart[] = [];

    showDisabled: boolean = false;

    constructor(init?: Partial<PlannedPartSearchFilter>) {
        super();
        if (init) Object.assign(this, init);
    }

    get toDTO(): PlannedPartSearchFilterDTO {
        return new PlannedPartSearchFilterDTO(this);
    }
}
